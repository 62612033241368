.kofi-widget-custom {
    position: fixed;
    bottom: 80px; /* Adjust this value to position it above the footer */
    right: 20px; /* Adjust this value to position it more to the left or right */
    z-index: 1000; /* Ensure it's above other elements */
  }
/* 
weight by user demographics
education
likelihood to change
religious affiliation
A/B testing - voter 
switch order of charts and 
 */